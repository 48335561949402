import { getApp } from '@firebase/app'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore'
import router from '../../router'
import { getHistoryFromCache, saveHistoryToCache } from '../../services/cache.service'
import { SteelspaceFileResponse } from '../../types'
import { authStore } from '../auth'
import { SteelspaceModel } from '../model/types'
import {
  historyStore,
  setHistoryErrorIcon,
  setHistoryErrorIconColor,
  setHistoryErrorMessage,
  setHistoryErrorTitle,
  setHistoryItem,
  setHistoryItemFiles,
  setHistoryItems,
  setHistoryLoading,
  setHistoryUnSubs,
} from './history.store'
import {
  compareHistoryItemsRequest,
  createHistoryItemRequest,
  deleteHistoryItemRequest,
  downloadHistoryItemFileRequest,
  editHistoryItemRequest,
  getHistoryFileRequest,
  getHistoryItemRequest,
  getModelHistoryFilesRequest,
  getModelHistoryRequest,
  restoreHistoryItemRequest,
} from './requests'
import { CompareOptions, CompareResult, HistoryItem, HistoryItemFile } from './types'

/////////////////////////
/////FIREBASE SDK//////
////////////////////////
export const getHistoryItemByIdAsync = async (id?: string): Promise<HistoryItem> => {
  if (
    (!historyStore.historyItem && id) ||
    (id && historyStore.historyItem && historyStore.historyItem.id !== id)
  )
    await getHistoryDocumentById(id)

  return historyStore.historyItem || new HistoryItem()
}

const getHistoryDocumentById = async (historyId: string): Promise<void> => {
  setHistoryLoading(true)
  const historyDocument = await getDoc(doc(getFirestore(getApp()), 'historyItems', historyId))

  const historyItemFiles = await getDocs(collection(historyDocument.ref, 'historyItemFiles'))

  const historyResult = new HistoryItem(historyDocument.data())
  historyItemFiles.docs.map((historyItemFile) =>
    historyResult.historyItemFiles.push(historyItemFile.data() as HistoryItemFile)
  )

  setHistoryItem(historyResult)
  setHistoryLoading(false)
}

export const getModelHistoryItemsSubscribe = (): HistoryItem[] => {
  if (!historyStore.historyItems.length) subscribeToHistoryItems()

  return historyStore.historyItems
}

export const unSubFromHistoryFirebaseSnapshots = (): void => {
  Object.values(historyStore.unSubs).forEach((unsub) => {
    unsub()
  })

  setHistoryUnSubs({})
}

const subscribeToHistoryItems = (): void => {
  const unsubKey = 'history'

  if (historyStore.unSubs[unsubKey] || !authStore.currentSteelspaceUser) {
    return
  }

  const queryHistoryItems = query(
    collection(getFirestore(getApp()), 'historyItems'),
    where('userData.id', '==', authStore.currentSteelspaceUser?.uid)
  )

  const unsubFromHistoryItemsUpdates = onSnapshot(queryHistoryItems, async (querySnapshot) => {
    setHistoryLoading(true)
    const promises = querySnapshot.docs.map(async (doc) => {
      const historyItem = new HistoryItem(doc.data())
      const historyItemFiles = await getDocs(collection(doc.ref, 'historyItemFiles'))
      historyItemFiles.docs.map((historyItemFile) =>
        historyItem.historyItemFiles.push(historyItemFile.data() as HistoryItemFile)
      )
      return historyItem
    })
    const historyItems = await Promise.all(promises)
    setHistoryItems(historyItems)
    setHistoryLoading(false)
  })

  historyStore.unSubs[unsubKey] = unsubFromHistoryItemsUpdates
}

/////////////////////////
/////REQUEST CALLS///////
/////////////////////////

export const compareHistoryItems = async (
  modelId: string,
  sourceHistoryItemId: string,
  targetHistoryItemId: string,
  options: CompareOptions
): Promise<CompareResult | undefined> => {
  setHistoryLoading(true)
  try {
    const res = await compareHistoryItemsRequest(
      modelId,
      sourceHistoryItemId,
      targetHistoryItemId,
      options
    )

    setHistoryLoading(false)
    return res
  } catch (error: any) {
    if (error instanceof Error) {
      setHistoryErrorMessage(error.message.toString())
      setHistoryErrorTitle('Comparing history items failed'.toString())
      setHistoryErrorIcon('mdi-close-circle')
      setHistoryErrorIconColor('error')
    }
    setHistoryLoading(false)
  }
}

export const getHistoryFile = async (
  modelId: string,
  historyId: string,
  fileId: string
): Promise<Blob | undefined> => {
  setHistoryLoading(true)

  let historyFile: SteelspaceFileResponse | undefined
  //we need to wrap this, firefox doesn't let us read IDB if in incognito
  try {
    const cached = await getHistoryFromCache(historyId)

    if (!cached) {
      //not in cache, dowlnload needed
      historyFile = await getHistoryFileRequest(modelId, historyId, fileId)
      if (!historyFile) return undefined

      await saveHistoryToCache(historyId, historyFile.file)

      setHistoryLoading(false)
      return historyFile.file
    }
    setHistoryLoading(false)
    return cached.file

    //if an error occurs, just download the model
  } catch {
    historyFile = await getHistoryFileRequest(modelId, historyId, fileId)
  }

  setHistoryLoading(false)
  return historyFile?.file
}

export const fetchModelHistoryItemsById = async (modelId: string): Promise<void> => {
  setHistoryLoading(true)

  const historyItems = await getModelHistoryRequest(modelId)

  if (!historyItems) {
    setHistoryItems([])
    setHistoryLoading(false)
    return
  }

  setHistoryItems(historyItems)
  setHistoryLoading(false)
}

export const getModelHistoryFiles = async (
  modelId: string,
  historyItemId: string
): Promise<HistoryItemFile[] | undefined> => {
  setHistoryLoading(true)
  try {
    const result = await getModelHistoryFilesRequest(modelId, historyItemId)
    setHistoryLoading(false)
    if (result) setHistoryItemFiles(result)
    return result
  } catch (error: any) {
    setHistoryErrorMessage(error)
    setHistoryLoading(false)
  }
}

export const fetchHistoryItemById = async (
  modelId: string,
  historyId: string
): Promise<HistoryItem | undefined> => {
  setHistoryLoading(true)

  const historyItem = await getHistoryItemRequest(modelId, historyId)
  if (!historyItem) {
    setHistoryLoading(false)
    return undefined
  }
  const historyItemFiles = await getModelHistoryFilesRequest(modelId, historyItem.id)

  if (historyItemFiles) historyItem.historyItemFiles = historyItemFiles

  setHistoryLoading(false)
  setHistoryItem(historyItem)

  return historyItem
}

export const createHistoryItem = async (
  model: SteelspaceModel,
  title: string,
  description: string
): Promise<HistoryItem | undefined> => {
  setHistoryLoading(true)

  try {
    const historyItem = await createHistoryItemRequest(model as SteelspaceModel, title, description)
    if (historyItem) await fetchModelHistoryItemsById(historyItem.modelId)
    setHistoryLoading(false)
    return historyItem
  } catch (error) {
    setHistoryLoading(false)

    if (error instanceof Error) {
      setHistoryErrorMessage(error.message)
      setHistoryErrorTitle('Error during create history')
    }
  }
}

export const editHistoryItem = async (historyItem: HistoryItem): Promise<void> => {
  setHistoryLoading(true)

  try {
    await editHistoryItemRequest(historyItem)
    await fetchModelHistoryItemsById(historyItem.modelId)
  } catch (error) {
    if (error instanceof Error) {
      setHistoryErrorMessage(error.message)
      setHistoryErrorTitle('Error during edit history')
    }
  }

  setHistoryLoading(false)
}

export const restoreHistoryItem = async (historyItemId: string): Promise<void> => {
  setHistoryLoading(true)
  const currHistoryItem = historyStore.historyItems?.find((item) => item.id === historyItemId)

  try {
    if (!currHistoryItem) throw Error('Can not find historyItem with id: ' + historyItemId)
    await restoreHistoryItemRequest(currHistoryItem as HistoryItem)
    await fetchModelHistoryItemsById(currHistoryItem.modelId)
  } catch (error) {
    if (error instanceof Error) {
      setHistoryErrorMessage(error.message)
      setHistoryErrorTitle('Error during restore history')
    }
  }

  setHistoryLoading(false)
}

export const deleteHistoryItem = async (historyItemId: string): Promise<void> => {
  setHistoryLoading(true)
  const currHistoryItem = historyStore.historyItems?.find((item) => item.id === historyItemId)

  if (!currHistoryItem) {
    setHistoryLoading(false)
    return
  }
  try {
    const result = await deleteHistoryItemRequest(currHistoryItem as HistoryItem)

    if (result) {
      await fetchModelHistoryItemsById(currHistoryItem.modelId)

      if (router.currentRoute.params.historyId === historyItemId)
        //TODO: reconsider
        router.push(`/model/${router.currentRoute.params.modelId}`)
    }
  } catch (error) {
    if (error instanceof Error) {
      setHistoryErrorMessage(error.message)
      setHistoryErrorTitle('Error during delete history')
      setHistoryErrorIcon('mdi-close-circle')
      setHistoryErrorIconColor('error')
    }
  }

  setHistoryLoading(false)
}

export const downloadHistoryItem = async (historyItemId: string): Promise<void> => {
  setHistoryLoading(true)

  const currHistoryItem = historyStore.historyItems?.find((item) => item.id === historyItemId)

  try {
    if (!currHistoryItem) {
      console.error('Current history item not found')
      return
    }
    if (!currHistoryItem.historyItemFiles?.length) {
      const result = await getModelHistoryFilesRequest(currHistoryItem.modelId, historyItemId)

      if (!result) {
        console.error('Model history files fetch failed')
        return
      }

      currHistoryItem.historyItemFiles = result
    }
    await downloadHistoryItemFileRequest(currHistoryItem as HistoryItem)
  } catch (error) {
    if (error instanceof Error) {
      setHistoryErrorMessage(error.message)
      setHistoryErrorTitle('Not enough bandwidth')
      setHistoryErrorIcon('mdi-close-circle')
      setHistoryErrorIconColor('error')
    }
  }

  setHistoryLoading(false)
}

///////////////////
/////OTHERS///////
///////////////////
export const parseHistoryItems = (items: HistoryItem[]): HistoryItem[] => {
  const sortedItems = items
    .map((i) => ({ ...i }))
    .sort((a, b) => {
      return a.creationDate > b.creationDate ? 1 : -1
    })

  const parsedItems = sortedItems
    .map((item, i) => {
      const historyItem = new HistoryItem(item)
      historyItem.number = `#${i + 1}`

      return historyItem
    })
    .reverse()

  return parsedItems
}
